



























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ShipArrival from '@/models/ShipArrival.model';
import {shipArrivalStoreActions} from '@/store/shipArrival.store';
import {namespace} from 'vuex-class';
import StatusHistory, {StatusType} from '@/models/StatusHistory.model';
import {BookingState} from '@/enums/BookingState.enum';
import {SlotType} from '@/enums/SlotType.enum';
import {UserRole} from '@/models/User.model';

const ShipArrivalStore = namespace('shipArrival');

@Component({
  components: {
    HistoryVoyageUpdateComponent: () => import(
        /* webpackChunkName: "HistoryVoyageUpdateComponent" */
        '@/components/shipArrival/history/HistoryVoyageUpdate.component.vue'),
    HistoryArrivalUpdateComponent: () => import(
        /* webpackChunkName: "HistoryArrivalUpdateComponent" */
        '@/components/shipArrival/history/HistoryArrivalUpdate.component.vue'),
    HistorySlotUpdateComponent: () => import(
        /* webpackChunkName: "HistorySlotUpdateComponent" */
        '@/components/shipArrival/history/HistorySlotUpdate.component.vue'),
  }
})
export default class ShipArrivalHistoryComponent extends Vue {

  @ShipArrivalStore.Action(shipArrivalStoreActions.GET)
  public loadShipArrivalDetails!: (payload: { id: string }) => Promise<ShipArrival>

  @Prop({default: () => new ShipArrival()})
  public shipArrival!: ShipArrival;

  public shipArrivalDetails: ShipArrival | null = null;

  public isDataLoading: boolean = false;

  public StatusType = StatusType;
  public BookingState = BookingState;
  public SlotType = SlotType;
  public UserRole = UserRole;

  @Watch('shipArrival', {immediate: true})
  private async shipArrivalChanged() {
    this.isDataLoading = true;
    this.shipArrivalDetails = await this.loadShipArrivalDetails({id: this.shipArrival!.id!});
    this.isDataLoading = false;
  }
}

